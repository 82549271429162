@import "../../includes/functions.scss";
.Footer {
  position: relative;
  width: 100%;
  background-color: var(--primary);
  padding: 3.5rem 0;
  .Logo {
    margin-bottom: 1rem;
  }
  .Text {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    strong {
      color: var(--white);
      margin-bottom: 1rem;
    }
  }

  &__socialMedia {
    margin-top: 2rem;
    &__icons {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      align-content: center;
      cursor: pointer;
      width: 50%;
      margin: 0 auto 1rem;
      @include respond-to($tablet) {
        width: 30%;
      }
      svg {
        transition: 300ms var(--default-cubic);
      }
      svg:hover {
        color: var(--dark-blue);
      }
    }
  }

  ul {
    list-style: none;
    display: inline-block;
    width: 100%;
    text-align: right;
    li {
      line-height: 2;
      margin-bottom: 1rem;
      a {
        display: block;
        font-size: 1.25rem;
        color: var(--white);
        &:hover,
        &:active {
          text-decoration: underline;
        }
      }
    }
  }
}
