@import "../../includes/functions.scss";
.Text {
  display: inline-block;
  margin: 0;
  width: 100%;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  strong {
    margin: 0;
    margin-bottom: 2rem;
    color: var(--dark-blue);
    &.primary {
      color: var(--primary);
    }
    &.upcase {
      text-transform: uppercase;
    }
  }
  &--primary {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    strong {
      color: var(--primary);
    }
  }
  p {
    font-size: 1rem;
    font-weight: normal;
  }
  h1 {
    @include respond-to($tablet) {
      font-size: 2.5rem;
    }
    font-size: 2rem;
    font-weight: bold;
  }
  h2 {
    @include respond-to($tablet) {
      font-size: 2rem;
    }
    font-size: 1.75rem;
    font-weight: normal;
  }
  h3 {
    @include respond-to($tablet) {
      font-size: 1.75rem;
    }
    font-size: 1.5rem;
    font-weight: normal;
  }
  h4 {
    @include respond-to($tablet) {
      font-size: 1.5rem;
    }
    font-size: 1.25rem;
    font-weight: normal;
  }
  h5 {
    @include respond-to($tablet) {
      font-size: 1.25rem;
    }
    font-size: 1.15rem;
    font-weight: normal;
  }
  h6 {
    font-size: 1rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
  }
  strong,
  &.strong {
    font-weight: bold;
  }
  &.w-regular {
    font-weight: normal !important;
  }
  &.align {
    &-left {
      text-align: left;
    }
    &-right {
      text-align: right;
    }
    &-center {
      text-align: center;
    }
    &-justify {
      text-align: justify;
    }
  }
  &.Text--hr {
    text-align: center;
    @include respond-to($tablet) {
      text-align: inherit;
    }
  }
  &___hr {
    max-width: 3.5rem;
    width: 100%;
    border: none;
    border-top: 2px solid var(--primary);
    margin: -1rem auto 2rem;
    @include respond-to($tablet) {
      margin-top: -1rem;
      margin-bottom: 2rem;
      margin-left: 0;
    }
  }
}
