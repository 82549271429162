@import "../../includes/functions.scss";
.Presentation {
  position: relative;
  min-height: 100vh;
  padding-top: 1rem;
  @include respond-to($tablet) {
    min-height: 60vh;
  }
  &__Image {
    position: relative;
    top: -2rem;
    right: 0;
    width: 100%;
    max-width: 1440px;
    z-index: -1;
    &__S {
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
    }

    @include respond-to($tablet) {
      position: absolute;
      &__S {
        position: absolute;
      }
    }
  }
  &__SaibaMais {
    position: relative;
    margin-top: -1rem;
    left: 0;
    right: 0;
    h5 {
      margin-bottom: 0.75rem;
    }
    @include respond-to($tablet) {
      position: absolute;
      bottom: 1rem;
    }
  }
}
