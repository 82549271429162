.AboutUs {
  padding: 7rem 0 0;
  &__shadowBox {
    background-color: var(--white);
    box-shadow: var(--boxShadow);
    border-radius: 1rem;
    height: 100%;
    padding: 2rem;
    text-align: center;
    &--margin {
      margin-bottom: 2rem;
    }
    &__icon {
      max-width: 6.875rem;
    }
    .Text {
      h4 {
        margin: 1rem 0 0.5rem;
        font-weight: 700;
        color: var(--secondary);
      }
      p {
        margin-bottom: 0;
      }
    }
  }
}
