@import "../../includes/functions.scss";

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  background-color: rgba($color: #000000, $alpha: 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  &__image {
    z-index: 1100;
    img {
      max-height: 70vh;
    }
  }
}
