@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;600;700;800&display=swap");

:root {
  --white: #ffffff;
  --blue: #3388ff;
  --secondary-blue: #3964a6;
  --light-blue: #d7e9fd;
  --dark-blue: #1b385b;
  --primary: var(--blue);
  --secondary: var(--secondary-blue);
  --background: var(--light-blue);
  --default-cubic: cubic-bezier(0.28, 0.34, 0.62, 0.95);
  --gradient-primary: linear-gradient(208deg, #66a6ff 9.05%, #0f70b7 76.74%);
  --gradient-secondary: linear-gradient(
    -45deg,
    rgba(215, 233, 253, 1) 15%,
    rgba(215, 233, 253, 0.4) 76.74%
  );
  --boxShadow: 0px 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
html {
  scroll-behavior: smooth;
}
* {
  box-sizing: border-box;
  outline: none;
}
body {
  margin: 0;
  font-family: "Mulish", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  color: var(--dark-blue);
  line-height: 1.4;
  overflow-x: hidden;
}
a {
  color: var(--primary);
  text-decoration: none;
  font-weight: 600;
  transition: 300ms var(--default-cubic);
  &:hover,
  &:focus,
  &:active {
    color: var(--secondary);
  }
}
img {
  max-width: 100%;
  height: auto;
}
