@import "../../includes/functions.scss";
.Navbar {
  margin: 1rem 0 1.5rem;
  padding: 0.5rem 0;
  position: relative; /* Safari */
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: var(--background);
  background: var(--gradient-secondary);
  transition: background-color 300ms var(--default-cubic),
    position 300ms var(--default-cubic);
  @include respond-to($tablet) {
    padding: 0.5rem 0;
  }
  &--fixed {
    margin: 0;
    position: fixed; /* Safari */
    top: 0;
    left: 0;
    right: 0;
  }
  &:hover {
    background-color: rgba(215, 233, 253, 0.9);
  }
  ul {
    list-style: none;
    display: inline-block;
    width: 100%;
    text-align: right;
    li {
      display: inline-block;
      margin-right: 1.5rem;
      line-height: 2;
      a {
        display: block;
        font-size: 1.25rem;
      }
    }
    &.mobileList {
      li {
        a {
          font-size: 1.5rem;
        }
      }
    }
  }
}
