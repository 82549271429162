@import "../../includes/functions.scss";
.ContactUs {
  padding: 7rem 0 18rem;
  position: relative;
  @include respond-to($tablet) {
    padding-bottom: 2rem;
  }
  &__Image {
    position: absolute;
    left: 0;
    width: 75%;
    max-width: 1440px;
    z-index: -1;
    bottom: -2rem;
    @include respond-to($tablet) {
      top: 7rem;
      width: 100%;
    }
    &__S {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
  a {
    font-weight: normal;
  }
}
