@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;600;700;800&display=swap);
:root{--white: #ffffff;--blue: #3388ff;--secondary-blue: #3964a6;--light-blue: #d7e9fd;--dark-blue: #1b385b;--primary: var(--blue);--secondary: var(--secondary-blue);--background: var(--light-blue);--default-cubic: cubic-bezier(0.28, 0.34, 0.62, 0.95);--gradient-primary: linear-gradient(208deg, #66a6ff 9.05%, #0f70b7 76.74%);--gradient-secondary: linear-gradient(
    -45deg,
    rgba(215, 233, 253, 1) 15%,
    rgba(215, 233, 253, 0.4) 76.74%
  );--boxShadow: 0px 0.5rem 1rem rgba(0, 0, 0, 0.15)}html{scroll-behavior:smooth}*{box-sizing:border-box;outline:none}body{margin:0;font-family:"Mulish", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#d7e9fd;background-color:var(--background);color:#1b385b;color:var(--dark-blue);line-height:1.4;overflow-x:hidden}a{color:#3388ff;color:var(--primary);text-decoration:none;font-weight:600;transition:300ms cubic-bezier(0.28, 0.34, 0.62, 0.95);transition:300ms var(--default-cubic)}a:hover,a:focus,a:active{color:#3964a6;color:var(--secondary)}img{max-width:100%;height:auto}


.AboutUs{padding:7rem 0 0}.AboutUs__shadowBox{background-color:var(--white);box-shadow:var(--boxShadow);border-radius:1rem;height:100%;padding:2rem;text-align:center}.AboutUs__shadowBox--margin{margin-bottom:2rem}.AboutUs__shadowBox__icon{max-width:6.875rem}.AboutUs__shadowBox .Text h4{margin:1rem 0 0.5rem;font-weight:700;color:var(--secondary)}.AboutUs__shadowBox .Text p{margin-bottom:0}

.Text{display:inline-block;margin:0;width:100%}.Text h1,.Text h2,.Text h3,.Text h4,.Text h5,.Text h6,.Text p,.Text strong{margin:0;margin-bottom:2rem;color:var(--dark-blue)}.Text h1.primary,.Text h2.primary,.Text h3.primary,.Text h4.primary,.Text h5.primary,.Text h6.primary,.Text p.primary,.Text strong.primary{color:var(--primary)}.Text h1.upcase,.Text h2.upcase,.Text h3.upcase,.Text h4.upcase,.Text h5.upcase,.Text h6.upcase,.Text p.upcase,.Text strong.upcase{text-transform:uppercase}.Text--primary h1,.Text--primary h2,.Text--primary h3,.Text--primary h4,.Text--primary h5,.Text--primary h6,.Text--primary p,.Text--primary strong{color:var(--primary)}.Text p{font-size:1rem;font-weight:normal}.Text h1{font-size:2rem;font-weight:bold}@media only screen and (min-width: 768px){.Text h1{font-size:2.5rem}}.Text h2{font-size:1.75rem;font-weight:normal}@media only screen and (min-width: 768px){.Text h2{font-size:2rem}}.Text h3{font-size:1.5rem;font-weight:normal}@media only screen and (min-width: 768px){.Text h3{font-size:1.75rem}}.Text h4{font-size:1.25rem;font-weight:normal}@media only screen and (min-width: 768px){.Text h4{font-size:1.5rem}}.Text h5{font-size:1.15rem;font-weight:normal}@media only screen and (min-width: 768px){.Text h5{font-size:1.25rem}}.Text h6{font-size:1rem;font-weight:normal;letter-spacing:0.05rem}.Text strong,.Text.strong{font-weight:bold}.Text.w-regular{font-weight:normal !important}.Text.align-left{text-align:left}.Text.align-right{text-align:right}.Text.align-center{text-align:center}.Text.align-justify{text-align:justify}.Text.Text--hr{text-align:center}@media only screen and (min-width: 768px){.Text.Text--hr{text-align:inherit}}.Text___hr{max-width:3.5rem;width:100%;border:none;border-top:2px solid var(--primary);margin:-1rem auto 2rem}@media only screen and (min-width: 768px){.Text___hr{margin-top:-1rem;margin-bottom:2rem;margin-left:0}}

.AnimateOnScroll{animation-timing-function:var(--default-cubic)}

.Navbar{margin:1rem 0 1.5rem;padding:0.5rem 0;position:relative;top:0;left:0;right:0;z-index:1000;background-color:var(--background);background:var(--gradient-secondary);transition:background-color 300ms var(--default-cubic),position 300ms var(--default-cubic)}@media only screen and (min-width: 768px){.Navbar{padding:0.5rem 0}}.Navbar--fixed{margin:0;position:fixed;top:0;left:0;right:0}.Navbar:hover{background-color:rgba(215,233,253,0.9)}.Navbar ul{list-style:none;display:inline-block;width:100%;text-align:right}.Navbar ul li{display:inline-block;margin-right:1.5rem;line-height:2}.Navbar ul li a{display:block;font-size:1.25rem}.Navbar ul.mobileList li a{font-size:1.5rem}

.LinksList{padding:0}.LinksList__block{display:block !important;text-align:center}@media only screen and (min-width: 768px){.LinksList__block{text-align:left}}

.Header{min-height:12rem}

.Portfolio{padding:7rem 0 0}

.Modal{position:fixed;top:0;left:0;right:0;bottom:0;width:100%;height:100%;z-index:1100;background-color:rgba(0,0,0,0.8);display:flex;align-items:center;justify-content:center;overflow-x:hidden}.Modal__image{z-index:1100}.Modal__image img{max-height:70vh}

.Carousel{cursor:-webkit-grab;cursor:grab;margin:2rem 0}.Carousel:active{cursor:-webkit-grabbing;cursor:grabbing}.Carousel__image{max-width:320px;width:100%;height:320px;position:relative;border-radius:1rem;padding:0 1rem;background-color:var(--white);box-shadow:var(--boxShadow);overflow:hidden}@media only screen and (max-width: 1200px){.Carousel__image{max-width:240px;height:240px}}.Carousel__image img{position:absolute;top:0;left:0;right:0;bottom:0;margin:auto;max-width:100%;max-height:100%;object-fit:cover;background-color:var(--white)}.Carousel .slick-list{padding:1rem}.Carousel .slick-slide{text-align:center}.Carousel .slick-next:before,.Carousel .slick-prev:before{font-size:2rem;line-height:1;opacity:0.75;color:var(--blue)}.Carousel .slick-dots li{margin:0 1px}@media only screen and (min-width: 768px){.Carousel .slick-dots li{margin:0 4px}}.Carousel .slick-dots li button:before{font-size:12px;color:var(--blue)}

.Presentation{position:relative;min-height:100vh;padding-top:1rem}@media only screen and (min-width: 768px){.Presentation{min-height:60vh}}.Presentation__Image{position:relative;top:-2rem;right:0;width:100%;max-width:1440px;z-index:-1}.Presentation__Image__S{position:absolute;top:0;right:0;z-index:-1}@media only screen and (min-width: 768px){.Presentation__Image{position:absolute}.Presentation__Image__S{position:absolute}}.Presentation__SaibaMais{position:relative;margin-top:-1rem;left:0;right:0}.Presentation__SaibaMais h5{margin-bottom:0.75rem}@media only screen and (min-width: 768px){.Presentation__SaibaMais{position:absolute;bottom:1rem}}

.UsServices{padding:7rem 0 0}.UsServices__header{background-color:var(--white);border-radius:1rem;display:flex;align-items:center;justify-content:flex-start;padding:1rem 1.5rem}.UsServices__header .Text h4{margin:0;font-size:1.25rem}.UsServices__header__icon{max-width:60px;width:100%;margin-right:1rem}.UsServices__list{padding-left:2rem;margin-bottom:2rem}

.ContactUs{padding:7rem 0 18rem;position:relative}@media only screen and (min-width: 768px){.ContactUs{padding-bottom:2rem}}.ContactUs__Image{position:absolute;left:0;width:75%;max-width:1440px;z-index:-1;bottom:-2rem}@media only screen and (min-width: 768px){.ContactUs__Image{top:7rem;width:100%}}.ContactUs__Image__S{position:absolute;top:0;left:0;z-index:-1}.ContactUs a{font-weight:normal}

.Button{display:inline-block;position:relative}.Button__container{display:block;width:100%;position:relative}.Button__container__align--center{text-align:center}.Button__container__align--right{text-align:right}.Button__container__align--left{text-align:left}.Button__container button,.Button__container a{text-decoration:none;text-align:center}.Button__container--icon button .icon,.Button__container--icon a .icon{display:inline-block;vertical-align:middle;margin-right:px(10)}.Button button,.Button a{text-transform:uppercase;font-weight:normal;font-size:1rem;line-height:1.5;background:var(--gradient-secondary);color:var(--primary);border-radius:0.25rem;border:2px solid var(--primary);cursor:pointer;padding:0.5rem 0.75rem;transition:background-color 400ms var(--default-cubic) 200ms,color 400ms var(--default-cubic)}.Button button:hover,.Button button:active,.Button a:hover,.Button a:active{background:var(--primary);color:var(--white);border:2px solid var(--primary)}.Button--fluid{display:block}.Button--fluid button,.Button--fluid a{width:100%}.Button--disabled button,.Button--disabled a{background:var(--secondary);cursor:default}

.Footer{position:relative;width:100%;background-color:var(--primary);padding:3.5rem 0}.Footer .Logo{margin-bottom:1rem}.Footer .Text h1,.Footer .Text h2,.Footer .Text h3,.Footer .Text h4,.Footer .Text h5,.Footer .Text h6,.Footer .Text p,.Footer .Text strong{color:var(--white);margin-bottom:1rem}.Footer__socialMedia{margin-top:2rem}.Footer__socialMedia__icons{margin-top:1rem;display:flex;justify-content:space-between;align-content:center;cursor:pointer;width:50%;margin:0 auto 1rem}@media only screen and (min-width: 768px){.Footer__socialMedia__icons{width:30%}}.Footer__socialMedia__icons svg{transition:300ms var(--default-cubic)}.Footer__socialMedia__icons svg:hover{color:var(--dark-blue)}.Footer ul{list-style:none;display:inline-block;width:100%;text-align:right}.Footer ul li{line-height:2;margin-bottom:1rem}.Footer ul li a{display:block;font-size:1.25rem;color:var(--white)}.Footer ul li a:hover,.Footer ul li a:active{text-decoration:underline}

