@import "../../includes/functions.scss";
.Carousel {
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  &__image {
    max-width: 320px;
    width: 100%;
    height: 320px;
    position: relative;
    border-radius: 1rem;
    padding: 0 1rem;
    background-color: var(--white);
    box-shadow: var(--boxShadow);
    overflow: hidden;
    @media only screen and (max-width: 1200px) {
      max-width: 240px;
      height: 240px;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;

      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
      background-color: var(--white);
    }
  }
  .slick-list {
    padding: 1rem;
  }
  .slick-slide {
    text-align: center;
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 2rem;
    line-height: 1;
    opacity: 0.75;
    color: var(--blue);
  }
  .slick-dots {
    li {
      margin: 0 1px;
      @include respond-to($tablet) {
        margin: 0 4px;
      }
      button:before {
        font-size: 12px;
        color: var(--blue);
      }
    }
  }
  margin: 2rem 0;
}
